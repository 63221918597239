import React, { useEffect, useState, useForm } from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import formatNumber from '../utilities/formatNumber';
import Modal from '../components/products/Modal';
import NoteModal from '../components/products/NoteModal';
import Btn from '../components/Btn';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';

const Products = ({
	productVariants,
	manufacturers
}) => {
	/*const {Search} = Input*/
	const { state, dispatch } = useStore();
	const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
	const [products, setProducts] = useState({});
	const [product, setProduct] = useState({});
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState(false);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	//Toggle for Note Modal
	const [showNoteModal, setShowNoteModal] = useState(false);

	//State for Accounts Select in Modal
	const [populateAccounts, setPopulateAccounts] = useState([]);
	const [categories, setCategories] = useState({});

	const [incomeAccounts, setIncomeAccounts] = useState([]);
	const [expenseAccounts, setExpenseAccounts] = useState([]);

	const populateAccountSelect = async e => {
		try {
			// Send query to our API
			const res = await fetch(`${config.base_api}/accounts`, {
				headers
			});

			if (!res.ok) {
				return;
			}

			let data = await convertJSON(res);
			//set State for All Accounts
			setPopulateAccounts(data);

			//Set State for Income Accounts
			let incomeAcc = data.filter(account => account.income === true);
			setIncomeAccounts(incomeAcc);

			//Set State for Expense Accounts
			let expenseAcc = data.filter(account => account.expense === true);
			setExpenseAccounts(expenseAcc);

		} catch (error) {
			console.log(error);
		}
	};

	const getProducts = async () => {
		try {
			const res = await fetch(`${config.base_api}/products/active`, { headers });
			const data = await convertJSON(res);

			if (res.ok) {
				setProducts(data.sort(compareProduct));
			}
			setLoading(false);
		} catch (error) {
			dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
			console.log(error);
		}
	}

	const productModal = async (productID) => {
		try {
			const res = await fetch(`${config.base_api}/products/sku/${productID}`, { headers });
			const data = await convertJSON(res);
			setProduct(data);

			//If Note Show Note Modal
			if (/^note-/.test(productID)) {
				setShowNoteModal(true);
			} else {
				setModal("update");
			}

		} catch (error) {
			dispatch({ type: ACTIONS.TOAST, payload: { message: "Could Not Load Product", isError: true } });
			console.log(error);
		}
	}

	const getCategories = async () => {
		try {
			const res = await fetch(`${config.base_api}/categories`, { headers });
			const data = await convertJSON(res);
			setCategories(data);

		} catch (e) {
			console.log(e)
		}
	}

	const exportCSV = () => {
		try {
			// Define which fields to add to CSV
			const csvRows = [];
			const includedFields = [
				{
					playground_boss_sku: 'PGB SKU'
				},
				{
					product_name: 'Name'
				},
				{
					manufacturer: 'Manufacturer'
				},
				{
					web_listed_price: 'Listed Price'
				},
				{
					cost: 'Cost'
				},
				{
					sale_price: 'Sale Price'
				},
				{
					ops_cost: 'Ops Cost'
				},
				{
					msrp: 'MSRP'
				},
				{
					is_palette: 'Is Palette'
				},
				{
					weight: 'Weight'
				},
				{
					crates: 'Crates'
				},
				{
					safety_use_zone: 'Safety Zone'
				},
				{
					activities: 'Activities'
				},
				{
					ages: 'Ages'
				},
				{
					child_capacity: 'Capacity'
				},
				{
					has_free_freight: 'Has Free Freight'
				},
				{
					freight_cost: 'Freight Cost'
				}
			];

			// Construct CSV array
			const includedFieldVariables = includedFields.map(field => Object.keys(field)[0]);
			const includedFieldNames = includedFields.map(field => Object.values(field)[0]);

			const csvHeaders = includedFieldNames.join(',');
			csvRows.push(csvHeaders);

			for (let i = 0; i < products.length; i++) {
				const product = products[i];
				const csvRow = [];

				for (let j = 0; j < includedFieldVariables.length; j++) {
					const fieldValue = product[includedFieldVariables[j]] || '';
					csvRow.push(fieldValue.toString().replace(',', ''));
				}

				csvRows.push(csvRow.join(','));
			}

			// Create and download CSV file from array
			const blob = new Blob([csvRows.join('\r\n')], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement('a')

			a.setAttribute('href', url)
			a.setAttribute('download', 'Product Sheet.csv');

			a.click()
		} catch (e) {
			console.log(e);
		}
	}

	const compareProduct = (a, b) => {
		if (a.playground_boss_sku > b.playground_boss_sku) {
			return 1;
		} else if (a.playground_boss_sku < b.playground_boss_sku) {
			return -1;
		} else {
			return 0;
		}
	}

	//Search Filter
	useEffect(() => {

		var sku = (Object.values(products).filter(
			sku => sku.playground_boss_sku.toString().toLowerCase().includes(searchValue.toLowerCase())
		));
		var name = (Object.values(products).filter(
			name => name.product_name.toString().toLowerCase().includes(searchValue.toLowerCase())
		));
		var manufacturer = (Object.values(products).filter(
			man => {
				if (man.manufacturer) {
					man.manufacturer.toString().toLowerCase().includes(searchValue.toLowerCase())
				}
			}
		));
		var web = (Object.values(products).filter(
			web => {
				if (web.web_listed_price) {
					web.web_listed_price.toString().includes(searchValue)
				}
			}
		));
		var cost = (Object.values(products).filter(
			cost => {
				if (cost.cost) {
					cost.cost.toString().includes(searchValue)
				}
			}
		));
		var sale = (Object.values(products).filter(
			sale => {
				if (sale.sale_price) {
					sale.sale_price.toString().includes(searchValue)
				}
			}
		));

		var all = sku.concat(name, manufacturer, web, cost, sale);
		var uniqueList = [...new Set(all)]
		setFilteredProducts(uniqueList);

	}, [searchValue]) // <-- here put the parameter to listen

	useEffect(() => {
		if (loading) {
			getProducts();
			populateAccountSelect();
			getCategories();
		}
	}, [loading])


	if (loading) {
		return <h1 className='centerText'>Products</h1>
	}

	return (
		<div>
			<h1 className='centerText'>Products</h1>
			<div id="productsHeader">
				<div>
					<label htmlFor='searchProducts'>
						Search Products:
					</label>
					<input id='searchProducts' onChange={(event) => setSearchValue(event.target.value)} value={searchValue} />
				</div>

				<div className='productButtons'>
					<div>
						<Btn title='Export to CSV' onClick={(event) => { event.preventDefault(); exportCSV() }}
							btnClass="green-button box-shadow min-width-150px justify-center pad-tb-md bg-light-green text-white text-bold light-green-border" />
					</div>

					<div>
						<Btn title='Create Product' onClick={(event) => { event.preventDefault(); setModal('add') }}
							btnClass='pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background' />
					</div>
				</div>

			</div>
			<div className="quotesTable">
				<table>
					<thead>
						<tr>
							<th className="text-left">PGB SKU</th>
							<th className="text-left">Name</th>
							<th colSpan="2" className="text-left">Manufacturer</th>
							<th className="text-left">Listed Price</th>
							<th className="text-left">Cost</th>
							<th className="text-left">Sale Price</th>
						</tr>
					</thead>
					<tbody>
						{searchValue ?
							(filteredProducts.map((product) => (
								<tr id={product.playground_boss_sku} onClick={() => productModal(product.playground_boss_sku)}>
									<td className = "pointer no-visited">
										{product.playground_boss_sku}
									</td>
									<td className = "pointer no-visited">
										{product.product_name}
									</td>
									<td colSpan="2" className = "pointer no-visited">
										{product.manufacturer}
									</td>
									<td className = "pointer no-visited">
										{
											product.web_listed_price ? (
												`$ ` + formatNumber(product.web_listed_price)

											) : (
												`none listed`
											)

										}
									</td>
									<td className = "pointer no-visited">
										{
											product.cost ? (
												`$ ` + formatNumber(product.cost)
											) : (
												`none listed`
											)

										}
									</td>
									<td className = "pointer no-visited">
										{
											product.sale_price ? (
												`$ ` + formatNumber(product.sale_price)
											) : (
												`none listed`
											)
										}
									</td>
								</tr>
							)))
							: (products.map((product) => (
								<tr id={product.playground_boss_sku} onClick={() => productModal(product.playground_boss_sku)}>
									<td className = "pointer no-visited">
										{product.playground_boss_sku}
									</td>
									<td className = "pointer no-visited">
										{product.product_name}
									</td>
									<td colSpan="2" className = "pointer no-visited">
										{product.manufacturer}
									</td>
									<td className = "pointer no-visited">
										{
											product.web_listed_price ? (
												`$ ` + formatNumber(product.web_listed_price)

											) : (
												`none listed`
											)

										}
									</td>
									<td className = "pointer no-visited">

										{
											product.cost ? (
												`$ ` + formatNumber(product.cost)
											) : (
												`none listed`
											)

										}
									</td>
									<td className = "pointer no-visited">
										{
											product.sale_price ? (
												`$ ` + formatNumber(product.sale_price)
											) : (
												`none listed`
											)
										}
									</td>
								</tr>
							)))
						}

					</tbody>
				</table>
			</div>
			<Modal
				show={modal}
				productVariants={productVariants}
				modal={modal}
				setModal={setModal}
				setProducts={setProducts}
				setProduct={setProduct}
				product={product}
				setLoading={setLoading}
				setSearchValue={setSearchValue}
				populateAccounts={populateAccounts}
				incomeAccounts={incomeAccounts}
				expenseAccounts={expenseAccounts}
				categories={categories}
				setCategories={setCategories}
				manufacturers={manufacturers}
			/>
			{showNoteModal &&
				<NoteModal
					setShowNoteModal={setShowNoteModal}
				/>
			}
		</div>
	)
}

export default Products
